import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export default function SkeletonBlock({
  className,
  width,
  height,
  maxWidth,
  maxHeight
}) {
  return (
    <div
      className={classnames(
        'SkeletonBlock bg-gray-300 bg-loading-animation br1',
        className
      )}
      style={{ width, height, maxWidth, maxHeight }}
    />
  );
}

SkeletonBlock.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SkeletonBlock.defaultProps = {
  className: null,
  width: null,
  height: null,
  maxWidth: '100%',
  maxHeight: '100%'
};
